.selected {
    background-color: #092b83;
    text-decoration: none;
    display: block;
}

.nav-link {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
    display: block;
    margin: 5px 10px;
    border-radius: 10px;
}

.logo {
    width: 220px;
    padding: 17px 10px 10px 10px;
}

.icon {
    margin-left: 30px;
    width: 19px;
}

.containerBackground {
    background-color: #0057FF;
    padding: 0;
}

.container {
    border-radius: 20px 0 0 0;
    background-color: #fff;
    padding: 20px 20px 0 20px;
}